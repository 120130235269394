<template>
  <b-breadcrumb class="mb-0">
    <b-breadcrumb-item
      v-if="parentItemOfPage !== null"
      :to="parentItemOfPage.to">
      <h2
        :id="`tooltip-button-variant-${parentItemOfPage.to}`"
        class="mb-0">
        {{ parentItemOfPage.text | sliceStingPos30 }}
      </h2>
      <b-tooltip
        v-if="parentItemOfPage.text.length > 30"
        :target="`tooltip-button-variant-${parentItemOfPage.to}`"
      >
        {{ parentItemOfPage.text }}
      </b-tooltip>
    </b-breadcrumb-item>
    <b-breadcrumb-item
      v-if="item"
      :to="item.to">
      <h2
        :id="`tooltip-button-variant-${item.to}`"
        class="mb-0">
        {{ item.text | sliceStingPos30 }}
      </h2>
      <b-tooltip
        v-if="item.text.length > 30"
        :target="`tooltip-button-variant-${item.to}`"
      >
        {{ item.text }}
      </b-tooltip>
    </b-breadcrumb-item>
    <b-breadcrumb-item
      v-else
      to="/">
      <h2 class="mb-0"> {{ $t('webmag.dashboard') }}</h2>
    </b-breadcrumb-item>
    <b-breadcrumb-item active>
      <h2 class="mb-0">{{ currentPageName }}</h2>
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
export default {
  name: 'SettingsBreadcrumb',
  props: {
    item: { type: Object },
    parentItemOfPage: { type: Object },
    currentPageType: { type: String },
  },
  computed: {
    currentPageName() {
      if (this.currentPageType === 'group-settings') {
        return this.$t('settings.group.breadcrumbName');
      }
      if (this.currentPageType === 'team-settings') {
        return this.$t('settings.team.breadcrumbName');
      } if (this.currentPageType === 'reports-settings') {
        return this.$t('settings.reports.breadcrumbName');
      }
      if (this.currentPageType === 'personalization-settings') {
        return this.$t('settings.personalization.breadcrumbName');
      }
      if (this.currentPageType === 'form-settings') {
        return this.$t('settings.forms.breadcrumbName');
      }
      return this.$t('webmag.settings');
    },
  },
  filters: {
    sliceStingPos30(value) {
      return (value.length > 30) ? `${value.slice(0, 30)}...` : value;
    },
  },
};
</script>

<style scoped>

</style>
