var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-breadcrumb",
    { staticClass: "mb-0" },
    [
      _vm.parentItemOfPage !== null
        ? _c(
            "b-breadcrumb-item",
            { attrs: { to: _vm.parentItemOfPage.to } },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-0",
                  attrs: {
                    id: `tooltip-button-variant-${_vm.parentItemOfPage.to}`
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("sliceStingPos30")(_vm.parentItemOfPage.text)
                      ) +
                      " "
                  )
                ]
              ),
              _vm.parentItemOfPage.text.length > 30
                ? _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: `tooltip-button-variant-${_vm.parentItemOfPage.to}`
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.parentItemOfPage.text) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.item
        ? _c(
            "b-breadcrumb-item",
            { attrs: { to: _vm.item.to } },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-0",
                  attrs: { id: `tooltip-button-variant-${_vm.item.to}` }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("sliceStingPos30")(_vm.item.text)) + " "
                  )
                ]
              ),
              _vm.item.text.length > 30
                ? _c(
                    "b-tooltip",
                    {
                      attrs: { target: `tooltip-button-variant-${_vm.item.to}` }
                    },
                    [_vm._v(" " + _vm._s(_vm.item.text) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _c("b-breadcrumb-item", { attrs: { to: "/" } }, [
            _c("h2", { staticClass: "mb-0" }, [
              _vm._v(" " + _vm._s(_vm.$t("webmag.dashboard")))
            ])
          ]),
      _c("b-breadcrumb-item", { attrs: { active: "" } }, [
        _c("h2", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.currentPageName))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }